<template>
  <VueSlickCarousel :class="['app-slider', centerMode ? 'center-mode' : null]" :center-mode="centerMode" v-bind="$attrs">
    <div class="slide-item" v-for="(item, index) in items" :key="index">
      <slot name="item" v-bind:item="item">
        <a href="#" @click.prevent="onSlideClick(item)" :title="item.title">
          <img :src="getWebFileUrl(item.imageUrl)" :alt="item.title">
        </a>
      </slot>      
    </div>
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "SlickSlider",
  components: {VueSlickCarousel},
  props: ["centerMode", 'items'],
  methods: {
    onSlideClick: function (item) {
      if (item.redirect)
        this.$redirectTo(item.redirect);
    }
  }
}
</script>