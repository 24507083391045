import httpHelper from "@/helpers/httpHelper";

const giftcodeService = {
    async codesForYou() {
        return await httpHelper.get("/WebApi/GiftCode/CodesForYou");
    },
    async getNotUseCodes(data) {
        return await httpHelper.get("/WebApi/GiftCode/GetNotUseCodes", data);
    },
    async getReceivedEventCodes(data) {
        return await httpHelper.get("/WebApi/GiftCode/GetReceivedEventCodes", data);
    },
    async getReceivedCodes(data) {
        return await httpHelper.get("/WebApi/GiftCode/GetReceivedCodes", data);
    },
    async getCodesInGame(data) {
        return await httpHelper.get("/WebApi/GiftCode/GetCodesInGame", data);
    },
    async getEventCodesInGame(data) {
        return await httpHelper.get("/WebApi/GiftCode/GetEventCodesInGame", data);
    },
    async getLogs(data) {
        return await httpHelper.get("/WebApi/GiftCode/GetLogs", data);
    },
    async detail(id) {
        return await httpHelper.get("/WebApi/GiftCode/Detail", {id});
    },
    async useGiftCode(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/GiftCode/UseGiftCode", data, {
            headers: {
                reCaptchaToken
            }
        });
    }
};

export default giftcodeService;