import httpHelper from "@/helpers/httpHelper";

const homeService = {
    async index() {
        return await httpHelper.get("/WebApi/Home/Index");
    },
    async getCurrentTime() {
        return await httpHelper.get("/WebApi/Home/GetCurrentTime");
    }
};

export default homeService;
