<template>
  <div class="post-card d-flex">
    <div class="post-thumbnail">
      <a href="#" :title="post.title" @click.prevent="$gotoPost(post)">
        <img :src="post.imageUrl.getUrl()" :alt="post.title" />
      </a>
    </div>
    <div class="post-content">
      <h3><a href="#" :title="post.title" @click.prevent="$gotoPost(post)">{{post.title}}</a></h3>
      <div class="game d-flex align-items-center">
        <img :src="post.gameIconUrl.getUrl()" :alt="post.gameName"/>
        <span>{{post.gameName}}</span>
      </div>
      <div class="other d-none d-md-block">
        <span class="category mr-1">{{post.categoryName}}</span>|<span class="time ml-1">{{$toDateTimeString(post.createdAt)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostCard",
  props: ["post"]
}
</script>

<style scoped lang="scss">
.post-card {
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;

  .post-thumbnail {
    -webkit-flex: 1 0 32.64%;
    -moz-box-flex: 1;
    flex: 1 0 32.64%;
    max-width: 32.64%;
    margin-right: 15px;

    img {
      width: 100%;
      border-radius: 15px;
    }
  }

  .post-content {
    h3 {
      font-size: 15px;
      margin-bottom: 8px;
      line-height: 1.5;
      font-weight: 400;
    }

    .game {
      img {
        height: 20px;
        width: 20px;
        margin-right: 7px;
      }
      
      span {
        font-size: 13.5px;
      }
    }

    .other {
      padding-top: 10px;
      font-size: 15px;

      .category {
        color: #1d73f1;
      }

      .time {
        color:  #8e8e93;
      }
    }
  }
}
</style>