<template>
  <div class="breadcrumb">
    <ul class="clearfix">
      <li v-for="(item, index) in breadcrumbItems" :key="index" class="breadcrumb-item float-left">
        <a href="#" @click.prevent="onClick(item)">
          {{item.label}}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["items", "hideHomePage"],
  data() {
    return {
      breadcrumbItems: [{
        label: this.$t("Home page"),
        click: () => this.$router.push({name: "Home"})
      }],
      breadcrumbLength: this.hideHomePage ? 0 : 1
    }
  },
  created() {
    if (this.items) {
      this.breadcrumbItems = [...this.breadcrumbItems, ...this.items];
      this.breadcrumbLength += this.items.length;
    }
  },
  methods: {
    onClick(item) {
      item.click && item.click();
    }
  }
}
</script>

<style scoped lang="scss">
  .breadcrumb {
    padding: 0;
    margin: 0 0 15px 0;
    background-color: transparent;
    
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      
      .breadcrumb-item {
        font-size: 14px;
        
        &:not(:first-child)::before {
          content: ">";
        }
        
        &:last-child a {
          color: #ee4623;
        }
      }
    }
  }
</style>