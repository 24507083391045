<template>
  <div id="sidebar" v-if="show">
    <div class="sidebar-container d-flex app-width h-100">
      <div class="sidebar d-block h-100">
        <perfect-scrollbar class="h-100">
          <header class="sidebar-header d-flex justify-content-between align-items-center">
            <img src="../../assets/images/logo_nav.png" style="max-width: calc(100% - 30px)"/>
            <a href="#" class="btn-close" @click.prevent="show = false">
              <b-icon icon="x-circle"/>
            </a>
          </header>
          <LoadingPanel v-if="loading"/>
          <div v-else>
            <div class="sidebar-menu" v-for="(menu, menuIndex) in menus" :key="menuIndex">
              <hr/>
              <ul>
                <li v-for="(item, itemIndex) in menu" :key="itemIndex">
                  <a href="#" @click.prevent="onMenuItemClick(item)">
                    <div class="icon">
                      <img :src="item.imageUrl.getUrl()" />
                    </div>
                    <span>
                    {{ $t(item.label) }}
                  </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import menuService from "@/services/menuService";

export default {
  name: "Sidebar",
  data() {
    return {
      show: false,
      loading: true,
      menus: [
        [{
          icon: "fa-solid fa-house",
          label: "Home page",
          type: "Route",
          value: "Home"
        }, {
          icon: "fa-solid fa-house",
          label: "Home page",
          type: "Route",
          value: "Home"
        }, {
          icon: "fa-solid fa-house",
          label: "Home page",
          type: "Route",
          value: "Home"
        }],
        [{
          icon: "fa-solid fa-house",
          label: "Home page",
          type: "Route",
          value: "Home"
        }, {
          icon: "fa-solid fa-house",
          label: "Home page",
          type: "Route",
          value: "Home"
        }, {
          icon: "fa-solid fa-house",
          label: "Home page",
          type: "Route",
          value: "Home"
        }]
      ]
    }
  },
  async created() {
    let menu = this.$store.getters.sidebarMenus;
    if (!menu) {
      const response = await menuService.get("sidebar");
      if (!response || response.error)
        menu = [];
      else
      {
        menu = response.data;
        await this.$store.dispatch("setSidebarMenus", menu);
      }
    }

    this.loading = false;
    this.menus = [menu];
  },
  methods: {
    onMenuItemClick(item) {
      this.show = false;
      this.$redirectTo(item.redirect);
    },
    showSidebar() {
      this.show = true;
    }
  }
}
</script>