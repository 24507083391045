<template>
  <div id="app" class="w-100 h-100">
    <div class="app-container app-width d-flex flex-column h-100">
      <div class="app-body" style="overflow: hidden">
        <perfect-scrollbar class="h-100" @ps-scroll-y="onScrollY" ref="pageScroll">
        <TitleBar />
        <router-view/>
          <input type="hidden" id="inputCopy">
        </perfect-scrollbar>
      </div>
      <ToolBar ref="toolbar"/>
    </div>
    <slideout-panel/>
  </div>
</template>

<script>
import ToolBar from "@/components/app/ToolBar";
import TitleBar from "@/components/app/TitleBar";
import homeService from "@/services/homeService";

export default {
  name: 'App',
  components: {ToolBar, TitleBar},
  methods: {
    onScrollY() {
      const ps = this.$refs.pageScroll.ps;
      if (ps.containerHeight + this.$refs.pageScroll.$el.scrollTop >= ps.contentHeight)
        this.$bridge.$emit('scrollToBottom');
    }
  },
  async created() {
    const result = await homeService.getCurrentTime();
    if (result && !result.error)
      await this.$store.dispatch("setCurrentTime", new Date(result.data));
  }
}
</script>
