import Vue from 'vue';
import {extend, localize, ValidationProvider, ValidationObserver} from "vee-validate";
import {required, email, min, max, confirmed} from "vee-validate/dist/rules";
import store from "@/store";
import viMessage from "../locale/vee-validate/vi.json";
import enMessage from "../locale/vee-validate/en.json";

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

const veeValidatePlugin = {
    register() {
        const locale = store.getters.locale;

        extend("required", required);
        extend("email", email);
        extend("min", min);
        extend("max", max);
        extend("confirmed", confirmed);

        localize({
            'en': enMessage,
            'vi': viMessage
        })

        localize(locale)
    }
};

export default veeValidatePlugin;