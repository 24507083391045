import Vue from "vue";
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

const scrollbarPlugin = {
    register() {
        Vue.use(PerfectScrollbar)
    }
}

export default scrollbarPlugin;