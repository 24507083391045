<template>
  <div class="app-features pt-4 d-flex justify-content-between flex-wrap" v-if="features.length > 0">
    <div class="item mb-2" v-for="(item, index) in features" :key="index">
      <a href="#" :title="item.label" @click.prevent="$redirectTo(item.redirect)">
        <img :src="getWebFileUrl(item.imageUrl)"/>
        <div>{{ item.label }}</div>
      </a>
    </div>
    <div class="item mb-2" v-if="showMore">
      <a href="#" :title="$t('View more')" @click.prevent="more">
        <img src="../../assets/images/icon/feature-more.svg"/>
        <div>{{ $t('View more') }}</div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeaturesPanel",
  props: ["items", "maxLength"],
  data() {
    return {
      features: [],
      showMore: false,
    }
  },
  created() {
    if (this.items) {
      this.features = this.maxLength ? this.items.filter((item, index) => index < this.maxLength) : this.items;
      this.showMore = this.features < this.items;
    }
  },
  methods: {
    async more() {
      window["FeaturePanel"] = this.$showPanel({
        component: 'ListFeaturesPanel',
        openOn: "bottom",
        height: (window.innerHeight / 100) * 70,
        props: {
          items: this.items
        }
      });

      window["FeaturePanel"].promise.then(result => {
        console.log(result);
      });
    }
  }
}
</script>
<style scoped lang="scss">
.app-features {
  .item a {
    display: block;
    width: 80px;
    text-align: center;
    font-size: 13px;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin-bottom: 7px;
    }
  }
}
</style>