<template>
  <div class="app-width h-100 gc-detail-panel">
    <perfect-scrollbar class="h-100">
      <b-col>
        <LoadingPanel v-if="loading"/>
        <div v-else-if="giftCode">
          <div class="panel-heading">
            <h4>{{giftCode.name}}</h4>
          </div>
          <div class="panel-body">
            <div class="gc-row gc-time"><b-icon icon="clock" class="mr-2"/>{{$toDateTimeString(giftCode.startDate)}} - {{$toDateTimeString(giftCode.endDate)}}</div>
            <div class="gc-row gc-conditions" v-if="giftCode.conditions.length">
              <div class="gc-row gc-title"><b-icon icon="check-all" class="mr-2"/>{{$t("Conditions get code")}}:</div>
              <div class="gc-row gc-condition-item" v-for="(condition, index) in giftCode.conditions" :key="index"><b-icon icon="check2-square" class="mr-2"/>{{condition}}</div>
            </div>
            <div class="gc-row gc-description" v-if="giftCode.description && giftCode.description.length"><b-icon icon="chat-right-text" class="mr-2"/>{{giftCode.description}}</div>
            <div class="gc-row gc-items" v-if="giftCode.description && giftCode.description.length"><b-icon icon="inboxes" class="mr-2"/>{{giftCode.items}}</div>
            <div class="gc-row gc-items text-success" v-if="giftCode.isReceived"><b-icon icon="check-circle" class="mr-2"/>{{$t("Qualification")}}</div>
            <div class="gc-row gc-items text-danger" v-else><b-icon icon="x-circle" class="mr-2"/>{{$t("Unqualified")}}</div>
            <div v-if="!giftCode.isReceived">
              <div class="g-note" v-html="$t('Report error code note', [giftCode.reportCrashUrl])"></div>
            </div>
          </div>
          <b-row v-if="giftCode.isReceived" class="buttons">
            <div class="d-flex justify-content-between w-100">
              <b-button variant="outline-primary" class="btn-rounded" v-if="giftCode.reportCrashUrl && giftCode.reportCrashUrl.length" @click="openLink(giftCode.reportCrashUrl)">{{$t("Report crash")}}</b-button>
              <b-button variant="primary" class="btn-rounded" v-if="giftCode.canUse" @click="$useGiftCode(giftCode)">{{$t("Use")}}</b-button>
              <b-button variant="primary" class="btn-rounded" v-if="giftCode.eventUrl && giftCode.eventUrl.length" @click="openLink(giftCode.eventUrl)">{{$t("Join a event")}}</b-button>
            </div>
          </b-row>
          <b-row v-else-if="giftCode.canJoin" class="buttons">
            <div class="d-flex justify-content-between w-100">
              <b-button variant="primary" class="btn-rounded" v-if="giftCode.eventUrl && giftCode.eventUrl.length" @click="openLink(giftCode.eventUrl)">{{$t("Join a event")}}</b-button>
            </div>
          </b-row><br>
        </div>
        <NotFoundPanel v-else />
      </b-col>
    </perfect-scrollbar>
  </div>
</template>

<script>
import giftcodeService from "@/services/giftcodeService";
import dialogHelper from "@/helpers/dialogHelper";
export default {
  name: "GiftCodeDetailPanel",
  props: ["id"],
  data() {
    return {
      loading: false,
      giftCode: null
    }
  },
  async created() {
    this.loading = true;
    const response = await giftcodeService.detail(this.id);
    this.loading = false;
    if (!response)
    {
      await dialogHelper.alert("Connect to server failed. Please check your internet connection");
      this.$hideGiftCodeDetail();
      return;
    }
    if (response.error) {
      await dialogHelper.alert(response.message);
      this.$hideGiftCodeDetail();
      return;
    }
    this.giftCode = response.data;
  },
  methods: {
    openLink(link) {
      location.href = link;
    }
  }
}
</script>

<style scoped lang="scss">
.gc-detail-panel {
  .panel-heading {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 0;
    h4 {
      margin: 0;
      font-weight: 700;
      font-size: 18px;
    }
  }
  .panel-body {
    padding: 15px 0;
    .gc-row {
      margin-bottom: 5px;
      font-size: 16px;
      .b-icon {
        color: #c2c2c2;
      }
      .gc-condition-item {
        font-size: 15px;
        padding-left: 20px;
      }
    }
  }
  .buttons {
    .btn {
      flex: 1;
      margin: 0 15px;
    }
  }
  .g-note {
    text-align: center;
    padding-top: 10px;
    font-size: 10.5px;
    font-style: italic;
    color: #0f4786;
    a {
      color: rgb(251, 95, 20) !important;
    }
  }
}
</style>
