import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import config from "@/config";
import store from "@/store";

Vue.use(VueRouter)

const publicPages = ["Home", "GiftCode", "GiftCodeIndex", "GameDetail", "PostDetail", "Posts", "Register", "Login", "RecoveryPassword", "ChangePasswordByCode", "Support"];

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {title: "Game Portal, HVN Mobile"}
    },
    {
        path: '/giftcode',
        name: 'GiftCode',
        component: () => import('../views/GiftCode.vue'),
        children: [{
            path: '',
            name: 'GiftCodeIndex',
            meta: {title: "Receive & Redeem giftcode HVN Game"},
            component: () => import("../views/giftcode/Index")
        }, {
            path: '/giftcode/received',
            name: 'ReceivedGiftCodes',
            meta: {title: "Receive & Redeem giftcode HVN Game"},
            component: () => import("../views/giftcode/Received")
        }, {
            path: '/giftcode/redeem-code',
            name: 'UseGiftCode',
            meta: {title: "Receive & Redeem giftcode HVN Game"},
            component: () => import("../views/giftcode/UseCode")
        }, {
            path: '/giftcode/redeem-success',
            name: 'UseGiftCodeSuccess',
            meta: {title: "Receive & Redeem giftcode HVN Game"},
            component: () => import("../views/giftcode/UseSuccess")
        }]
    },
    {
        path: '/giftcode/redeem-code-history',
        name: 'UseGiftHistory',
        meta: {title: "Receive & Redeem giftcode HVN Game"},
        component: () => import("../views/giftcode/History")
    },
    {
        path: '/game/:aliasUrl',
        name: 'GameDetail',
        component: () => import('../views/game/Detail')
    },
    {
        path: '/post/:aliasUrl',
        name: 'PostDetail',
        component: () => import('../views/post/Detail')
    },
    {
        path: '/post',
        name: 'Posts',
        component: () => import('../views/post/Category'),
        meta: {title: "News - Events"}
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/authenticate/Register'),
        meta: {title: "Register"}
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/authenticate/Login'),
        meta: {title: "Sign Up"}
    },
    {
        path: '/recovery-password',
        name: 'RecoveryPassword',
        component: () => import('../views/authenticate/RecoveryPassword'),
        meta: {title: "Forgot Password?"}
    },
    {
        path: '/recovery-password/change-password',
        name: 'ChangePasswordByCode',
        component: () => import('../views/authenticate/ChangePassword'),
        meta: {title: "Change Password"}
    },
    {
        path: "/giftcode/:aliasUrl",
        name: "GameGiftCode",
        component: () => import("../views/game/giftcode/Template"),
        children: [{
            path: "",
            name: "GameGiftCodeIndex",
            component: () => import("../views/game/giftcode/Index"),
        }, {
            path: "/giftcode/:aliasUrl/received",
            name: "GameGiftCodeReceived",
            component: () => import("../views/game/giftcode/Received"),
        }, {
            path: "/giftcode/:aliasUrl/redeem-code",
            name: "GameGiftCodeUseCode",
            component: () => import("../views/game/giftcode/UseCode"),
        }, {
            path: "/giftcode/:aliasUrl/redeem-success",
            name: "GameGiftCodeUseSuccess",
            component: () => import("../views/game/giftcode/UseSuccess"),
        }]
    },
    {
        path: '/recharge',
        name: 'ChargeCard',
        component: () => import('../views/chargeCard/Index'),
        meta: {title: "Top Up"}
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import('../views/account/Index'),
        meta: {title: "Account Information"}
    },
    {
        path: '/account/update-email',
        name: 'AccountUpdateEmail',
        component: () => import('../views/account/UpdateEmail'),
        meta: {title: "Update Email"}
    },
    {
        path: '/account/update-phone',
        name: 'AccountUpdatePhone',
        component: () => import('../views/account/UpdatePhone'),
        meta: {title: "Update phone number"}
    },
    {
        path: '/account/change-password',
        name: 'AccountChangePassword',
        component: () => import('../views/account/ChangePassword'),
        meta: {title: "Change Password"}
    },
    {
        path: '/account/log',
        name: 'AccountHistory',
        component: () => import('../views/account/History'),
        meta: {title: "Usage log"}
    },
    {
        path: '/account/vip',
        name: 'Vip',
        component: () => import('../views/account/vip/Index'),
        meta: {title: "VIP"}
    },
    {
        path: '/support',
        name: 'Support',
        component: () => import('../views/support/Index'),
        meta: {title: "Support"}
    },
    {
        path: '/game/picker/:callbackRoute/:idFeature',
        name: 'GamePicker',
        component: () => import('../views/game/Picker'),
        meta: {title: "Select a game"}
    },
    {
        path: '/game/:aliasUrl/change-diamond',
        name: 'BuyDiamond',
        component: () => import('../views/game/buyDiamond/Index'),
        meta: {title: "Buy diamond"}
    },
    {
        path: '/game/:aliasUrl/lucky-wheel',
        name: 'BuyDiamond',
        component: () => import('../views/game/luckyWheel/Index'),
        meta: {title: "Lucky wheel"}
    },
    {
        path: '/game/:aliasUrl/buy-gold',
        name: 'BuyGold',
        component: () => import('../views/game/buyGold/Index'),
        meta: {title: "Buy gold"}
    },
    {
        path: '/game/:aliasUrl/web-shop',
        name: 'WebShop',
        component: () => import('../views/game/webShop/Index'),
        meta: {title: "Webshop"}
    },
    {
        path: '/game/:aliasUrl/point-shop',
        name: 'PointShop',
        component: () => import('../views/game/pointShop/Index'),
        meta: {title: "Point shop"}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (!publicPages.includes(to.name) && !store.getters.account) {
        next({name: "Login", query: {returnUrl: to.path}});
    } else
        next()
})

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        if (to.meta && to.meta.title) {
            const configTitle = config.title;
            document.title = configTitle.prefix + to.meta.title + configTitle.suffix;
        }
    })
});

export default router
