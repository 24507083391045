<template>
  <div>
    <b-alert variant="danger" show class="text-center">
      {{message}}
      <div v-if="!hideRetryButton" class="pt-2">
        <b-button variant="danger" @click="$emit('retry')">{{$t("Retry")}}</b-button>
      </div>      
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "ErrorPanel",
  props: ["message", "hideRetryButton"]
}
</script>