import Vue from 'vue';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

const bootstrapPlugin = {
    register() {
        Vue.use(BootstrapVue)
        Vue.use(IconsPlugin)
    }
}

export default bootstrapPlugin;