<template>
  <div class="py-4 text-center">
    <b-col>
      <b-spinner class="mr-2" small/><small>{{$t("Loading data")}}</small>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "LoadingPanel"
}
</script>