import Vue from "vue";
import config from "@/config";
import moment from "moment";

import "../assets/css/style.css";
import {redirectModes} from "@/contants";
import dialogHelper from "@/helpers/dialogHelper";

import ListFeaturesPanel from "@/components/panels/ListFeaturesPanel";
import NotFoundPanel from "@/components/panels/NotFoundPanel";
import LoadingPanel from "@/components/panels/LoadingPanel";
import ErrorPanel from "@/components/panels/ErrorPanel";
import Breadcrumb from "@/components/breadcrumbs/Breadcrumb";
import LoadingModal from "@/components/modals/LoadingModal";
import GiftCodeDetailPanel from "@/components/panels/giftcode/GiftCodeDetailPanel";
import RowLine from "@/components/lines/RowLine";

let giftCodeDetailPanel;

const appPlugin = {
    extensions() {
        Vue.prototype.$bridge = new Vue();
        Vue.prototype.$config = config;

        String.prototype.getUrl = function (defaultUrl) {
            const url = defaultUrl || this;
            if (url.startsWith("http://") || url.startsWith("https://"))
                return url;

            return config.apiUrl + url;
        }

        Number.prototype.$toString = function () {
            return new Intl.NumberFormat().format(this);
        }

        Vue.prototype.$gotoHomePage = async function (routeName = "Home") {
            this.$router.push({name: routeName});
        }

        Vue.prototype.$insertToClipboard = async (text, inputId) => {
            const input = document.getElementById(inputId || "inputCopy");
            if (!input)
                return;

            input.value = text;
            if (!inputId)
                input.setAttribute('type', 'text');
            input.select();

            try {
                document.execCommand('copy');
            } catch (e)
            {
                console.log(e);
            }

            if (!inputId)
                input.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();

            await dialogHelper.alert("Copy successful", "success");
        }

        Vue.prototype.$getViewCounter = function (number, view = true) {
            let con = 1000;
            if (number < con)
                number = con;

            if (number >= 1000000)
                con = 1000000;

            if (view)
                return this.$t(con === 1000 ? "{0} K+ view" : "{0} M+ view", [Math.round(number/con)]);

            return this.$t(con === 1000 ? "{0} K+ played" : "{0} M+ played", [Math.round(number/con)]);
        }

        Vue.prototype.$toDateTimeString = function (d) {
            if (typeof d === "string")
                d = new Date(d);

            return moment(d).format(this.$t("Format_DateTime"));
        }

        Vue.prototype.$showGiftCodeDetail = function (id) {
            giftCodeDetailPanel = this.$showPanel({
                component: 'GiftCodeDetailPanel',
                openOn: "bottom",
                height: (window.innerHeight / 100) * 50,
                props: {
                    id: id
                }
            });

            giftCodeDetailPanel.promise.then(result => {
                console.log(result);
            });
        };

        Vue.prototype.$hideGiftCodeDetail = function () {
            if (giftCodeDetailPanel) {
                giftCodeDetailPanel.hide();
                giftCodeDetailPanel = null;
            }
        };

        Vue.prototype.$useGiftCode = function (giftCode) {
            this.$hideGiftCodeDetail();

            this.$router.push({
                name: "GameGiftCodeUseCode",
                params: {
                    aliasUrl: giftCode.game.aliasUrl
                },
                query: {
                    idEvent: giftCode.id,
                    nameEvent: giftCode.name,
                    showCode: giftCode.isMultiple
                }
            });
        }

        Vue.prototype.$setPageTitle = (str) => {
            if (str)
                str = config.title.prefix + str + config.title.suffix;
            else
                str = config.title.default;

            document.title = str;
        }

        Vue.prototype.$redirectTo = async function (data) {
            if (data.mode === redirectModes.ROUTE_NAME) {
                if (this.$route.name === data.value)
                    return;

                let params = data.params;
                if (typeof params === "string")
                    params = JSON.parse(params);

                let query = data.query;
                if (typeof query === "string")
                    query = JSON.parse(query);


                await this.$router.push({name: data.value || data.data, params: params, query: query});
                return;
            }

            if (data.mode === redirectModes.REDIRECT_URL)
            {
                location.href = data.value || data.data;
                return;
            }

            if (data.mode === redirectModes.SHOW_ALERT)
            {
                await dialogHelper.alert(data.value || data.data)
                return;
            }
        }

        Vue.prototype.$gotoPost = async function (post) {
            const oldRoute = this.$route.name;
            await this.$router.push({name: "PostDetail", params: {id: post.id, aliasUrl: post.aliasUrl}})
            if (oldRoute === "PostDetail")
                location.reload();
        }
    },
    register() {
        this.extensions();


        Vue.prototype.getWebFileUrl = function (url) {
            if (url.startsWith("http://" || url.startsWith("https://")))
                return url;

            return config.apiUrl + url;
        }

        Vue.prototype.toDateString = function (d) {
            if (typeof d === "string")
                d = new Date(d);

            return moment(d).format(this.$t("Format_Date"));
        }

        Vue.prototype.toDateTimeString = function (d) {
            if (typeof d === "string")
                d = new Date(d);

            return moment(d).format(this.$t("Format_DateTime"));
        }

        Vue.component("NotFoundPanel", NotFoundPanel);
        Vue.component("LoadingPanel", LoadingPanel);
        Vue.component("ListFeaturesPanel", ListFeaturesPanel);
        Vue.component("ErrorPanel", ErrorPanel);
        Vue.component("Breadcrumb", Breadcrumb);
        Vue.component("LoadingModal", LoadingModal);
        Vue.component("GiftCodeDetailPanel", GiftCodeDetailPanel);
        Vue.component("RowLine", RowLine);
    }
}

export default appPlugin;
