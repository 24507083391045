<template>
  <div class="h5-games" v-if="games && games.length > 0">
    <div class="header d-flex justify-content-between align-items-center">
      <h3 class="title">{{$t("Game H5 - Play now")}}</h3>
    </div>
    <div class="content">
      <SlickSlider :items="games" :arrows="false" :dots="false" :autoplay="true" :autoplay-speed="10000" :responsive="[{breakpoint: 500, settings: {slidesToShow: 4}}]" :slides-to-show="5">
        <template v-slot:item="slotProps">
          <div class="game-item">
            <a href="#" @click.prevent="$router.push({name: 'GameDetail', params: {aliasUrl: slotProps.item.aliasUrl}})">
              <div class="icon-game">
                <img :src="getWebFileUrl(slotProps.item.iconUrl)" :title="slotProps.item.name" />
                <div :class="['status-game', 'status-' + slotProps.item.status]" v-if="slotProps.item.status">{{$t("GameStatus_" + slotProps.item.status)}}</div>
              </div>
              <label>
                {{slotProps.item.name}}
              </label>
              <div class="view-count">{{$getViewCounter(slotProps.item.viewCounter, false)}}</div>
            </a>
          </div>
        </template>
      </SlickSlider>
    </div>
  </div>
</template>

<script>
import SlickSlider from "@/components/sliders/SlickSlider";
export default {
  name: "H5GamesPanel",
  components: {SlickSlider},
  props: ["items"],
  data() {
    return {
      keyword: "",
      games: []
    }
  },
  created() {
    this.games = this.items || []
  }
}
</script>

<style scoped lang="scss">
.h5-games {
  margin-bottom: 15px;
  .header {
    .title {
      font-weight: 600;
      font-size: 20px;
      margin: 0;
      min-width: 140px;
    }

    .search-form {
      position: relative;

      .icon {
        position: absolute;
        color: gray;
        top: 11px;
        left: 12px;
      }

      input {
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
        padding: 10px 0 10px 36px;
        background: #f4f4f4;
        border-radius: 100px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 13px;
      }
    }
  }

  .content {
    padding-top: 15px;

    .game-item {
      margin-bottom: 15px;
      padding-left: 5px;
      padding-right: 5px;

      .icon-game {
        position: relative;
        overflow: hidden;
        margin-bottom: 5px;
        border-radius: 16px;

        img {
          width: 100%;
        }

        .status-game {
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          -webkit-transform: translate(50%,50%) rotate(-45deg);
          transform: translate(50%,50%) rotate(-45deg);
          width: 50px;
          height: 50px;
          text-align: center;
          text-transform: uppercase;
          color: #fff;
          font-weight: 600;
          font-size: 10px;
          padding-top: .1em;
          background-color: red;

          &.status-2 {
            background-color:  #f99f1b;
          }
        }
      }

      label {
        font-size: 14px;
        display: -webkit-box;
        width: 100%;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        margin-bottom: 0;
      }

      .view-count {
        color: #8e8e93;
        font-size: 0.75em;
      }
    }
  }
}
</style>
