import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from "@/store";
import viMessage from "../locale/language/vi.json";
import enMessage from "../locale/language/en.json";

const i18nPlugin = {
    register() {
        
        Vue.use(VueI18n);
        const locale = store.getters.locale;
        
        return new VueI18n({
            locale,
            messages: {
                en: enMessage,
                vi: viMessage
            }, 
            fallbackLocale: locale
        })
    }
}

export default i18nPlugin;