import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const dialogPlugin = {
    register() {
        Vue.use(VueSweetalert2);
    }
};

export default dialogPlugin;