import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18nPlugin from "@/plugins/i18nPlugin";
import veeValidatePlugin from "@/plugins/veeValidatePlugin";
import bootstrapPlugin from "@/plugins/bootstrapPlugin";
import scrollbarPlugin from "@/plugins/scrollbarPlugin";
import slideOutPanelPlugin from "@/plugins/slideOutPanelPlugin";
import reCaptchaV3Plugin from "@/plugins/reCaptchaV3Plugin";
import dialogPlugin from "@/plugins/dialogPlugin";
import appPlugin from "@/plugins/appPlugin";

Vue.config.productionTip = false;

const i18n = i18nPlugin.register();
veeValidatePlugin.register();
bootstrapPlugin.register();
scrollbarPlugin.register();
slideOutPanelPlugin.register();
reCaptchaV3Plugin.register();
dialogPlugin.register();
appPlugin.register();

const app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default app;
