<template>
  <div class="newest-posts" v-if="items && items.length > 0">
    <div class="header d-flex justify-content-between align-items-center">
      <h3 class="title">{{$t("News - Events")}}</h3>
      <a href="#" @click.prevent="$router.push({name: 'Posts'})">{{$t("View all")}}</a>
    </div>
    <div class="content">
      <div class="posts">
        <PostCard v-for="(post, index) in items" :key="index" :post="post"/>
      </div>
    </div>
  </div>
</template>

<script>
import PostCard from "@/components/cards/PostCard";

export default {
  name: "NewestPosts",
  props: ["items"],
  components: {PostCard}
}
</script>

<style scoped lang="scss">
.newest-posts {
  .header {
    .title {
      font-weight: 600;
      font-size: 20px;
      margin: 0;
    }
    
    a {
      color: #ee4623;
    }
  }

  .content {
    padding-top: 5px;
  }
}
</style>