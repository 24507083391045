<template>
  <div class="mobile-games">
    <div class="header d-flex justify-content-between align-items-center">
      <h3 class="title">{{$t("Game mobile")}}</h3>
      <div class="search-form">
        <div class="icon">
          <b-icon icon="search"/>
        </div>
        <input type="search" v-model="keyword" :placeholder="$t('Search games')">
      </div>
    </div>
    <div class="content">
      <NotFoundPanel v-if="games.length === 0" :message="$t('Cannot find games')" />
      <div class="games" v-else>
        <b-row>
          <b-col cols="4" sm="3" v-for="(game, index) in games" :key="index" class="item">
            <a href="#" @click.prevent="$router.push({name: 'GameDetail', params: {aliasUrl: game.aliasUrl}})">
              <div class="icon-game">
                <img :src="getWebFileUrl(game.iconUrl)" :title="game.name" />
                <div :class="['status-game', 'status-' + game.status]" v-if="game.status">{{$t("GameStatus_" + game.status)}}</div>
              </div>
              <label>
                {{game.name}}
              </label>
            </a>
            <div class="view-count">
              <a :href="game.downloadUrl" v-if="game.downloadUrl && game.downloadUrl.length">{{$t("Download")}}</a>
              <span v-if="game.downloadUrl && game.downloadUrl.length && game.fanpageUrl && game.fanpageUrl.length">|</span>
              <a :href="game.fanpageUrl" v-if="game.fanpageUrl && game.fanpageUrl.length">{{$t("Fanpage")}}</a>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileGamesPanel",
  props: ["items"],
  data() {
    return {
      keyword: "",
      games: []
    }
  },
  created() {
    this.games = this.items || []
  },
  watch: {
    keyword(newValue) {
      let dataSource = this.items || [];
      if (newValue && newValue.length > 0) {
        const keyword = newValue.toLowerCase();
        dataSource = dataSource.filter(x => x.name.toLowerCase().indexOf(keyword) > -1 || x.uName.toLowerCase().indexOf(keyword) > -1);
      }

      this.games = dataSource;
    }
  }
}
</script>

<style scoped lang="scss">
.mobile-games {
  margin-bottom: 15px;

  .header {
    .title {
      font-weight: 600;
      font-size: 20px;
      margin: 0;
      min-width: 140px;
    }

    .search-form {
      position: relative;

      .icon {
        position: absolute;
        color: gray;
        top: 11px;
        left: 12px;
      }

      input {
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
        padding: 10px 0 10px 36px;
        background: #f4f4f4;
        border-radius: 100px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 13px;
      }
    }
  }

  .content {
    padding-top: 15px;

    .games {
      .row {
        margin-left: -5px;
        margin-right: -5px;

        .item {
          margin-bottom: 15px;
          padding-left: 5px;
          padding-right: 5px;

          .icon-game {
            position: relative;
            overflow: hidden;
            margin-bottom: 5px;
            border-radius: 16px;

            img {
              width: 100%;
            }

            .status-game {
              display: block;
              position: absolute;
              bottom: 0;
              right: 0;
              -webkit-transform: translate(50%,50%) rotate(-45deg);
              transform: translate(50%,50%) rotate(-45deg);
              width: 70px;
              height: 70px;
              text-align: center;
              text-transform: uppercase;
              color: #fff;
              font-weight: 600;
              font-size: 12px;
              padding-top: .1em;
              background-color: red;

              &.status-2 {
                background-color:  #f99f1b;
              }
            }
          }

          label {
            font-size: 14px;
            display: -webkit-box;
            width: 100%;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 1;
            margin-bottom: 0;
            text-align: center;
          }

          .view-count {
            color: #8e8e93;
            font-size: 0.75em;
            text-align: center;
            display: flex;
            gap: 5px;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
