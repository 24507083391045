<template>
  <b-overlay :show="show" no-wrap style="z-index: 10" fixed>
    
  </b-overlay>
</template>

<script>
export default {
  name: "LoadingModal",
  props: ["show", "message"]
}
</script>

<style scoped>

</style>