<template>
  <b-row>
    <div class="w-100 py-3">
      <hr/>
    </div>
  </b-row>
</template>

<script>
export default {
  name: "RowLine"
}
</script>