<template>
  <div class="app-width h-100 full-features">
    <perfect-scrollbar class="h-100">
      <b-col>
        <div class="item" v-for="(item, index) in items" :key="index">
          <a href="#" class="d-flex align-items-center" @click.prevent="onItemClick(item.redirect)">
            <img :src="getWebFileUrl(item.imageUrl)" :alt="item.label"/>
            <div class="content">
              <h4>{{item.label}}</h4>
              <div>{{item.description}}</div>
            </div>
          </a>
        </div>
      </b-col>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "ListFeaturesPanel",
  props: ["items"],
  methods: {
    onItemClick(redirect) {
      if (window["FeaturePanel"]) {
        window["FeaturePanel"].hide();
        delete window["FeaturePanel"];
      }
      
      this.$redirectTo(redirect)
    }
  }
}
</script>

<style scoped lang="scss">
.full-features {
  .item {
    padding: 7px 0;
    
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-right: 10px;
    }
    
    .content {
      h4 {
        margin: 0;
        font-size: 15px;
      }

      div {
        font-size: 12px;
        color: #8e8e93;
      }
    }
  }
}
</style>