<template>
  <div class="giftcodes-for-you" v-if="loading || (items && items.length > 0)">
    <div class="header">
      {{$t("Giftcodes for you")}}
    </div>
    <div class="content">
      <LoadingPanel v-if="loading" />
      <SlickSlider v-if="items && items.length > 0" :items="items" :arrows="false" :dots="false" :autoplay="true" :autoplay-speed="5000" :responsive="[{breakpoint: 500, settings: {slidesToShow: 1}}]" :slides-to-show="2">
        <template v-slot:item="slotProps">
          <div class="giftcode d-flex" v-for="(code, index) in slotProps.item" :key="index" @click="$showGiftCodeDetail(code.id)">
            <img :src="code.game.iconUrl.getUrl()" :alt="code.gameName" class="game-icon" />
            <div class="giftcode-content">
              <h4>{{code.name}}</h4>
              <div class="game-name">{{code.game.name}}</div>
            </div>
          </div>
        </template>
      </SlickSlider>
    </div>
  </div>
</template>

<script>
import giftcodeService from "@/services/giftcodeService";
import SlickSlider from "@/components/sliders/SlickSlider";

export default {
  name: "GiftcodesForYouPanel",
  components: {SlickSlider},
  data() {
    return {
      loading: true,
      items: [],
    }
  },
  async created() {
    let giftcodes = this.$store.getters.giftCodesForYou;
    if (giftcodes) {
      this.loading = false;
    }
    else {
      const response = await giftcodeService.codesForYou();
      this.loading = false;
      if (!response || response.error)
        return;

      giftcodes = response.data;
      await this.$store.dispatch("setGiftCodesForYou", giftcodes);
    }

    const items = [];
    giftcodes.forEach((giftCode, index) => {
      if (index%2 == 0) {
        items.push([giftCode]);
      }
      else {
        items[items.length-1].push(giftCode)
      }
    });

    this.items = items;
  }
}
</script>

<style scoped lang="scss">
.giftcodes-for-you {
  .header {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
  }

  .content {
    padding: 15px 0;

    .slide-item {
      .giftcode {
        padding: 10px 0;

        &:first-child {
          border-bottom: 1px solid #f2f2f2;
        }

        .game-icon {
          width: 64px;
          height: 64px;
          object-fit: contain;
          margin-right: 15px;
        }

        .giftcode-content {
          h4 {
            display: -webkit-box;
            width: 100%;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden!important;
            -webkit-line-clamp: 2;
            font-size: 15px;
            line-height: 1.5;
            max-height: 45px;
            margin-bottom: 0;
          }

          .game-name {
            font-size: 12px;
            color: #61616a;
          }
        }
      }
    }

  }
}
</style>
