<template>
  <div class="notifications">
    <b-dropdown right variant="link" toggle-class="text-decoration-none" no-caret offset="100px" @shown="loadNotifications">
      <template #button-content>
        <div class="position-relative">
          <img src="../../assets/images/icon/noti-icon.svg" />
          <div class="has-new" v-if="hasNew"></div>
        </div>
      </template>
      <b-dropdown-header>
        <div class="d-flex justify-content-between">
          <div>{{$t("Notifications")}}</div>
          <a href="#" @click.prevent="read()"><img src="../../assets/images/icon/check-double.svg" />{{$t("Mark read")}}</a>
        </div>
      </b-dropdown-header>
      <LoadingPanel v-if="loading"/>
      <NotFoundPanel v-else-if="notifications.length === 0"/>
      <div v-else>
        <b-dropdown-item href="#" :class="['notification-item', notification.readAt ? 'read' : null]" v-for="(notification, index) in notifications" :key="index" @click.prevent="read(notification.id)">
          <h3 class="notification-title">{{notification.title}}</h3>
          <div class="notification-content" v-html="notification.content"></div>
          <div class="d-flex align-items-center">
            <img :src="notification.game.iconUrl.getUrl()"/> {{notification.game.name}} <span class="notification-time">· {{$toDateTimeString(notification.createAt)}}</span>
          </div>
        </b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import notificationService from "@/services/notificationService";

export default {
  name: "NotificationsDropDown",
  data() {
    return {
      hasNew: false,
      loading: false,
      notifications: []
    }
  },
  created() {
    this.$bridge.$on("hasNewNotification", this.setHasNewNotifications);
  },
  beforeDestroy() {
    this.$bridge.$off("hasNewNotification", this.setHasNewNotifications);
  },
  methods: {
    setHasNewNotifications(hasNew) {
      this.hasNew = hasNew;
    },
    async loadNotifications() {
      this.loading = true;
      const response = await notificationService.getTop10();
      if (!response || response.error) {
        this.loading = false;
        return;
      }
      
      const data = response.data;
      this.hasNew = data.hasNew;
      this.notifications = data.notifications;
      this.loading = false;
    },
    async read(id) {
      const response = await notificationService.read(id);
      if (!response || response.error)
        return;
      
      if (id) {
        const notification = this.notifications.find(x => x.id === id);
        if (notification)
          notification.readAt = new Date();
      }
      else {
        this.notifications.forEach(notification => notification.readAt = new Date());
      }

      const data = response.data;
      this.hasNew = data.hasNew;
    }
  },
}
</script>

<style lang="scss">
.notifications {
  .has-new {
    display: block;
    width: 12px;
    height: 12px;
    background-color: red;
    position: absolute;
    border-radius: 50%;
    top: 0;
    right: 0;
    border: 1px solid #fff;
  }

  .dropdown-menu {
    width: 320px !important;
    padding: 0;

    .dropdown-header {
      padding: 15px;
      font-size: 16px;
      font-weight: 700;
      color: #000;
      border-bottom: 1px solid #f2f2f2;

      a {
        color: #c7c7cc;
      }
    }
    
    .notification-item {      
      &:hover, &:not(.read) {
        background-color: #F0F0F0;
      }
      
      a {
        border-bottom: 1px solid #f2f2f2;
        padding: 16px;
        
        .notification-title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0;
        }
        
        .notification-content {
          font-size: 12px;
          color: #1f1f1f;
          margin-bottom: 5px;
          white-space: break-spaces !important;
        }
        
        .d-flex {
          font-size: 12px;
          img {
            height: 16px;
            width: 16px;
            margin-right: 3px;
          }
          
          .notification-time {
            color: #c3c1be;
            padding-left: 4px;
          }
        }
      }
    }
  }
}
</style>