<template>
  <div class="page page-home">
    <div class="col-md-12 text-center">
      {{currentTime}}
    </div>
    <div class="col-md-12 text-center pt-2" style="color: red; font-weight: 600" v-if="noticeBar.length > 0">
      <marquee onmouseover="this.stop();" onmouseout="this.start();">
        <div style="display: flex; flex-direction: row" v-html="noticeBar">

        </div>
      </marquee>
    </div>
    <div class="pt-3" v-if="data">
      <SlickSlider :items="data.sliders.home" :arrows="false" :dots="true" :center-mode="true" :autoplay="true" :autoplay-speed="10000" center-padding="20px"/>
      <b-col>
        <div class="mb-3">
          <FeaturesPanel :items="data.menus.features" :max-length="7"/>
        </div>
        <H5GamesPanel :items="data.games.h5" />
        <MobileGamesPanel :items="data.games.mobile" />
        <div class="mb-3">
          <GiftcodesForYouPanel />
        </div>
        <NewestPostsPanel :items="data.posts.newsest"/>
      </b-col>
    </div>
    <b-col v-else-if="errorMessage">
      <div class="pt-3">
        <ErrorPanel :message="errorMessage" @retry="loadData()"/>
      </div>
    </b-col>
    <b-col v-else>
      <LoadingPanel/>
    </b-col>
  </div>
</template>

<script>
import SlickSlider from "@/components/sliders/SlickSlider";
import FeaturesPanel from "@/components/panels/FeaturesPanel";
import MobileGamesPanel from "@/components/panels/MobileGamesPanel";
import NewestPostsPanel from "@/components/panels/NewestPostsPanel";
import H5GamesPanel from "@/components/panels/H5GamesPanel";
import GiftcodesForYouPanel from "@/components/panels/giftcode/GiftcodesForYouPanel";

import homeService from "@/services/homeService";
import dialogHelper from "@/helpers/dialogHelper";
import moment from "moment";

export default {
  name: 'Home',
  components: {SlickSlider, FeaturesPanel, MobileGamesPanel, NewestPostsPanel, GiftcodesForYouPanel, H5GamesPanel},
  data() {
    return {
      data: null,
      errorMessage: null,
      slideItems: [],
      currentTime: null,
      noticeBar: ''
    }
  },
  async created() {
    this.data = this.$store.getters.homeData;
    if (!this.data)
      await this.loadData();

    const notices = this.data.notices;
    if (notices && notices.length)
      notices.forEach(async (notice) => dialogHelper.alert(notice.content, "info", {
        title: notice.title
      }));

    if (this.data.hasNewNotification)
      this.$bridge.$emit("hasNewNotification", true);

    let html = '';
    this.data.barNotices.forEach(notice => {
      if (html.length > 0)
        html += '<span style="padding-left: 50px; display: flex; flex-direction: row; align-items: center; gap: 5px">';
      else
        html += '<span style="display: flex; flex-direction: row; align-items: center; gap: 5px">';

      if (notice.game) {
        html += "<span>"
        if (notice.game.iconUrl != null && notice.game.iconUrl.length > 0)
          html += `<img src="${notice.game.iconUrl.getUrl()}" style="height: 24px; width: 24px; object-fit: contain; margin-right: 5px" />`;

        html += notice.game.name + ': ';
        html += '</span>'
      }

      html += `<span style="height: 20px">${notice.content}</span>`;

      html += '</span>'
    });
    this.noticeBar = html;

    this.setTime();
    setInterval(this.setTime, 5000);
  },
  methods: {
    async loadData() {
      this.errorMessage = null;
      const response = await homeService.index();
      if (!response) {
        this.errorMessage = this.$t("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        this.errorMessage = response.message;
        return;
      }

      this.data = response.data;
      await this.$store.dispatch("setHomeData", this.data);
    },
    setTime() {
      this.currentTime = this.$t("Server time: {{serverTime}}", {serverTime: moment(this.$store.getters.currentTime).utc().format("YYYY-MM-DD HH:mm")}) ;
    }
  },
}
</script>
