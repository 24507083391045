const config = {
    "appName": "HVN Game",
    "_apiUrl": "http://localhost:5000",
    "apiUrl": "https://services.hvngame.com",
    "moneyName": "VNĐ",
    "goldName": "Coin",
    "pointName": "Point",
    "title": {
        "prefix": "",
        "suffix": " | game android, game ios, new game mobile, game mobile, game naruto, game one piece, free game, download game mobile",
        "default": ""
    },
    "locale": {
        "default": "en",
        "data": [{
            "code": "en",
            "full_code": "vi-VN",
            "name": "Tiếng Việt"
        }, {
            "code": "en",
            "full_code": "en-US",
            "name": "English"
        }]
    },
    "reCaptchaV3": {
        "siteKey": "6LcuGd0ZAAAAADjOtrnOgJHgJdcO5kiHM4fUqzGY",
    }
};

export default config;
