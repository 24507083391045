import httpHelper from "@/helpers/httpHelper";

const notificationService = {
    async getTop10() {
        return await httpHelper.get("/WebApi/Notification/GetTop10");
    },
    async read(id) {
        return await httpHelper.get("/WebApi/Notification/Read", id ? {id} : null);
    }
};

export default notificationService;