import Vue from 'vue'
import Vuex from 'vuex'
import config from "@/config";
import app from "@/main";
import {NU} from "../../../GamePortal/wwwroot/themes/admin-lte/plugins/pdfmake/pdfmake";

Vue.use(Vuex)

let currentTimeTimer = null;
export default new Vuex.Store({
  state: {
    locale: null,
    account: null,
    accessToken: null,
    homeData: null,
    giftCodesForYou: null,
    games: null,
    recoveryPassword: null,
    giftCodeSuccess: null,
    currentGame: null,
    chargeCardTypes: null,
    supportMenus: null,
    sidebarMenus: null,
    currentTime: null
  },
  getters: {
    currentTime: (state) => state.currentTime || new Date(),
    locale(state) {
      if (state.locale)
        return state.locale;

      // if (navigator.languages) {
      //   const bLocale = navigator.languages[0];
      //   if (config["locale"]["data"].find(x => x["code"] === bLocale))
      //   {
      //     state.locale = bLocale;
      //     return bLocale;
      //   }
      // }

      try {
        const sLocale = window.localStorage.getItem("LOCALE");
        if (sLocale)
        {
          state.locale = sLocale;
          return sLocale;
        }
      } catch (e) {
        console.log(e);
      }

      return config["locale"]["default"];
    },
    account(state) {
      const account = state.account;
      if (account)
        return account;

      try {
        const json = window.sessionStorage.getItem("ACCOUNT")
        if (json && json.length > 0)
          return JSON.parse(json);
      } catch (e) {
        console.log(e);
      }

      return null;
    },
    accessToken(state) {
      const accessToken = state.accessToken;
      if (accessToken && accessToken.length > 0)
        return accessToken;

      try {
        return window.sessionStorage.getItem("ACCESS_TOKEN");
      } catch (e) {
        console.log(e);
      }

      return null;
    },
    homeData: state => state.homeData,
    giftCodesForYou: state => state.giftCodesForYou,
    games: state => state.games,
    recoveryPassword: state => state.recoveryPassword,
    giftCodeSuccess: state => state.giftCodeSuccess,
    currentGame: state => state.currentGame,
    chargeCardTypes: state => state.chargeCardTypes,
    supportMenus: state => state.supportMenus,
    sidebarMenus: state => state.sidebarMenus
  },
  mutations: {
    SET_LOCALE: (state, payload) => {
      app.$i18n.locale = payload;
      state.locale = payload;
      try {
        window.localStorage.setItem("LOCALE", payload);
      } catch (e) {
        console.log(e);
      }
    },
    SET_USER_INFO: (state, payload) => state.userInfo = payload,
    SET_HOME_DATA: (state, payload) => state.homeData = payload,
    SET_GIFTCODES_FOR_YOU: (state, payload) => state.giftCodesForYou = payload,
    SET_GAMES: (state, payload) => state.games = payload,
    SET_SUPPORT_MENUS: (state, payload) => state.supportMenus = payload,
    SET_ACCOUNT(state, payload) {
      const account = payload ? {...payload} : null;
      try {
        if (account)
        {
          account.avatarChar = account.displayName.toUpperCase().charAt(0);
          window.sessionStorage.setItem("ACCOUNT", JSON.stringify(account));
        }
        else
          window.sessionStorage.removeItem("ACCOUNT")
      } catch (e) {
        console.log(e);
      }

      state.account = account;
    },
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload;
      try {
        if (payload)
          window.sessionStorage.setItem("ACCESS_TOKEN", payload);
        else
          window.sessionStorage.removeItem("ACCESS_TOKEN")
      } catch (e) {
        console.log(e);
      }
    },
    SET_RECOVERY_PASSWORD: (state, payload) => state.recoveryPassword = payload,
    SET_GIFTCODE_SUCCESS: (state, payload) => state.giftCodeSuccess = payload,
    SET_CURRENT_GAME: (state, payload) => state.currentGame = payload,
    SET_CHARGE_CARD_TYPES: (state, payload) => state.chargeCardTypes = payload,
    SET_SIDEBAR_MENUS: (state, payload) => state.sidebarMenus = payload,
    SET_CURRENT_TIME: (state, payload) => {
      if(currentTimeTimer) {
        clearInterval(currentTimeTimer);
        currentTimeTimer = null;
      }

      state.currentTime = payload
      currentTimeTimer = setInterval(() => {
        state.currentTime = new Date(state.currentTime.getTime() + 1000);
      }, 1000);
    }
  },
  actions: {
    setAccount: ({commit}, payload) => commit("SET_ACCOUNT", payload),
    setLocale: ({commit}, payload) => commit("SET_LOCALE", payload),
    setHomeData: ({commit}, payload) => commit("SET_HOME_DATA", payload),
    setGiftCodesForYou: ({commit}, payload) => commit("SET_GIFTCODES_FOR_YOU", payload),
    setGames: ({commit}, payload) => commit("SET_GAMES", payload),
    setAccessToken: ({commit}, payload) => commit("SET_ACCESS_TOKEN", payload),
    setRecoveryPassword: ({commit}, payload) => commit("SET_RECOVERY_PASSWORD", payload),
    setGiftCodeSuccess: ({commit}, payload) => commit("SET_GIFTCODE_SUCCESS", payload),
    setCurrentGame: ({commit}, payload) => commit("SET_CURRENT_GAME", payload),
    setChargeCardTypes: ({commit}, payload) => commit("SET_CHARGE_CARD_TYPES", payload),
    setSupportMenus: ({commit}, payload) => commit("SET_SUPPORT_MENUS", payload),
    setSidebarMenus: ({commit}, payload) => commit("SET_SIDEBAR_MENUS", payload),
    setCurrentTime: ({commit}, payload) => commit("SET_CURRENT_TIME", payload),
  }
})
