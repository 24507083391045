import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import config from "@/config";

const reCaptchaV3Plugin = {
    register() {
        Vue.use(VueReCaptcha, {
            siteKey: config.reCaptchaV3.siteKey
        })
    }
}

export default reCaptchaV3Plugin;