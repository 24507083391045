<template>
  <div class="not-found">
    <img src="../../assets/images/not-found.png" :alt="$t('Không tìm thấy game nào!')"/>
    <div class="message">{{ message || $t('Content not found') }}</div>
  </div>
</template>

<script>
export default {
  name: "NotFoundPanel",
  props: ["message"]
}
</script>

<style scoped lang="scss">
.not-found {
  padding: 30px 0;
  text-align: center;

  img {
    width: 32%;
    max-width: 190px;
    margin-bottom: 20px;
  }
}
</style>