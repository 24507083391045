<template>
  <div class="app-toolbar bg-luna-new-year">
    <nav class="nav-toolbar w-100">
      <ul>
        <li v-for="(item, index) in menus" :key="index" :class="currentRoute === item.value ? 'active' : null">
          <a href="#" class="align-items-center justify-content-center" @click.prevent="onToolbarClick(item)">
            <img :src="item.iconUrl" :alt="item.label"/>
            <span>{{ $t(item.label) }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import imgHome from "@/assets/images/icon/homepage.svg";
import imgGiftCode from "@/assets/images/icon/giftcode.svg";
import imgCharge from "@/assets/images/icon/nap.svg";
import imgSupport from "@/assets/images/icon/info.svg";
import imgAccount from "@/assets/images/icon/account.svg";
import {redirectModes} from "@/contants";

export default {
  name: "ToolBar",
  data() {
    return {
      currentRoute: "Home",
      menus: [{
        iconUrl: imgHome,
        label: "Home page",
        mode: redirectModes.ROUTE_NAME,
        value: "Home"
      }, {
        iconUrl: imgGiftCode,
        label: "Giftcode",
        mode: redirectModes.ROUTE_NAME,
        value: "GiftCodeIndex"
      }, {
        iconUrl: imgCharge,
        label: "Charge card",
        mode: redirectModes.ROUTE_NAME,
        value: "ChargeCard"
      }, {
        iconUrl: imgSupport,
        label: "Support",
        mode: redirectModes.ROUTE_NAME,
        value: "Support"
      }, {
        iconUrl: imgAccount,
        label: "Account",
        mode: redirectModes.ROUTE_NAME,
        value: "Account"
      }]
    }
  },
  created() {
    this.currentRoute = this.$route.name;
  },
  methods: {
    onToolbarClick(item) {
      this.$redirectTo(item);
    }
  },
  watch: {
    "$route.name": function (newValue) {
      this.currentRoute = newValue;
    }
  }
}
</script>