<template>
  <div class="app-titlebar bg-luna-new-year">
    <Sidebar ref="sidebar"/>
    <b-col class="d-flex align-items-center">
      <div class="app-action-sidebar mr-2">
        <a href="#" @click.prevent="$refs.sidebar.showSidebar()">
          <b-icon icon="list" />
        </a>
      </div>
      <div class="app-title">
        <a href="/" @click.prevent="gotoRoute('Home')">
          <img src="../../assets/images/logo.png" />
        </a>
      </div>
      <div class="app-menus">
        <div class="acc-logged d-flex" v-if="account" >
          <div class="notifications">
            <NotificationsDropDown />
          </div>
          <a class="acc d-flex align-items-center" href="#" @click.prevent="$router.push({name: 'Account'})">
            <div class="acc-avatar mr-2">
              {{account.avatarChar}}
            </div>
            <div class="acc-name">
              {{account.displayName}}
            </div>
          </a>
        </div>
        <b-button v-else variant="primary" class="btn-rounded font-weight-bold" @click="gotoRoute('Login')">{{$t("Login")}}</b-button>
      </div>
    </b-col>
  </div>
</template>

<script>
import Sidebar from "@/components/app/Sidebar";
import NotificationsDropDown from "@/components/dropdowns/NotificationsDropDown";

export default {
  name: "TitleBar",
  components: {Sidebar, NotificationsDropDown},
  data() {
    return {
      account: this.$store.getters.account
    }
  },
  created() {
    this.$bridge.$on("accountLogin", this.setAccount);
  },
  beforeDestroy() {
    this.$bridge.$off("accountLogin", this.setAccount)
  },
  methods: {
    gotoRoute(name){
      if(this.$route.name === name)
        return;
     
      this.$router.push({name:name});
    },
    setAccount(signOut) {
      this.account = signOut ? null : this.$store.getters.account;
    }
  }
}
</script>
<style lang="scss">
.acc-logged {
  .acc {
    .acc-avatar {
      display: block;
      width: 28px;
      height: 28px;
      background-color: #ee4623;
      border-radius: 50%;
      color: #ffffff;
      text-align: center;
      line-height: 32px;
      font-size: 18px;
      font-weight: 900;
    }
  }
}

</style>